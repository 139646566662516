import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView>
          <CFImage
            h="150px"
            src={logo}
            alt="Baik Me Korean Restaurant Logo"
            mt="-10px"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" zIndex={98} maxWidth="1400px">
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            ml="-100px"
            mt="-80px"
          >
            <CFImage
              w="600px"
              src={logo}
              alt="Baik Me Korean Restaurant Logo"
              zIndex={98}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
